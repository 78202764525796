import axios from 'axios'
import { decryptUtil,encryptUtil } from '../Utils/decrypt'; 
const instance = axios.create({

      // baseURL: 'https://www.dev.taskicabs.com/serviceprovider'
     //baseURL: 'https://stagesp.taski.in/web'
      // baseURL: 'https://devsp.taski.in/web',
       //baseURL: 'https://www.taskicabs.com/adminbackend/servicePartner',
        baseURL: 'https://sp.taski.in/web'       
//     baseURL: 'https://stagesp.taski.in/web'
    // baseURL: 'http://192.168.29.55:2020/adminbackend/servicePartner',
    // baseURL: 'http://10.114.20.165:2020/adminbackend/servicePartner',
    // baseURL: 'http://192.168.29.244:80/adminbackend/servicePartner',
    // baseURL:'http://localhost:2027/adminbackend/servicePartner',
    // baseURL:'http://localhost:80/web',
    // baseURL:'http://localhost:2027/web',
    // baseURL: 'http://localhost:8080/web'
    // baseURL:'https://sp.taski.in/web'
    // baseURL:'http://192.168.2.73:2027/web',
            // baseURL:'http://192.168.2.235:8080/web'
            // baseURL:'http://192.168.2.104:90/web'
                        // baseURL:'http://192.168.2.235:80/web'
  
    
})

const token = localStorage.getItem('vendorLoginToken');
if(token)  instance.defaults.headers.common['token'] = token



instance.interceptors.response.use(
    (response) => {
        try {
            const decryptedData = new decryptUtil({ encrypted: response.data }).getDecryptedData();
            response.data = decryptedData;
        } catch (error) {
           console.log(error)
        }
        return response;
    },
    (error) => {
        console.error('Axios Error:', error.message);
        return Promise.reject(error);
    }
);



instance.interceptors.request.use(
    async (config) => {
        const branch = JSON.parse(localStorage.getItem('selectedBranch'));
        if (branch) {
            config.headers['branch'] = branch.id; 
        }
        if (config.data && !(config.data instanceof FormData)) {
            const encryptedPayload = new encryptUtil({ decrypt: config.data }).getEncryptedData();
            config.data = {
                data: encryptedPayload,
            };
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);



export default instance
    
